import mobileLanding from '../assets/landing/moibleLanding.png';
import landingMap from '../assets/landing/landingMap.webp';
import styled from 'styled-components';
import { FlexBox } from 'styles/GlobalStyle';
import mokpoLanding from '../assets/landing/mokpoLanding.webp';
import mokpoLandingFire from '../assets/landing/mokpoLandingFire.webp';
import { MOBILE_MAX_WIDTH, PAD_MAX_WIDTH } from 'const/overview';
function Landing() {
    console.log(window.innerWidth);
    return (
        <>
            {window.innerWidth <= 1280 && (
                <MobileNavigate>
                    <p>목포W쇼 축제 정보를 한눈에 보고 싶다면?</p>
                    <MobileBtn
                        onClick={() =>
                            window.open('https://wshowmokpo.co.kr', '_self')
                        }
                    >
                        모바일 홈페이지 바로가기
                    </MobileBtn>
                </MobileNavigate>
            )}
            <LandingWshowContainer>
                <LandingContainer>
                    {window.innerWidth < 1280 ? (
                        <MobileLandingWshowImg src={mobileLanding} alt="" />
                    ) : (
                        <LandingWshowImg src={mokpoLanding} alt="" />
                    )}
                </LandingContainer>
            </LandingWshowContainer>
            <LandingFireBox>
                <FireImg src={mokpoLandingFire} />
                <TextContainer>
                    <TextBox>
                        <h1>행사일정 안내</h1>
                        <TimeBox>
                            <h2>전체 공연 4회</h2>
                            <h3>PM 8:00 ~ 9:00</h3>
                        </TimeBox>
                        <Line />
                        <TimeBox style={{ marginTop: '16px' }}>
                            <div>
                                <h2>정기 공연 3회</h2>
                                <p>평화광장 해상무대 </p>
                            </div>
                            <div>
                                <h3>4월 27일</h3>
                                <h3 style={{ marginTop: '24px' }}>5월 25일</h3>
                                <h3 style={{ marginTop: '24px' }}>9월 14일</h3>
                            </div>
                        </TimeBox>
                        <Line />
                        <TimeBox style={{ marginTop: '16px' }}>
                            <div>
                                <h2>특별공연 1회</h2>
                                <p>북항노을공원 일원</p>
                            </div>
                            <h3>7월 27일</h3>
                        </TimeBox>
                        <InfoText>*상기 일정은 변경될 수 있습니다.</InfoText>
                        <InfoText style={{ marginTop: '4px' }}>
                            {' '}
                            홈페이지와 SNS를 확인하여주세요.
                        </InfoText>
                    </TextBox>
                </TextContainer>
            </LandingFireBox>
            <MapImg loading="lazy" src={landingMap} alt=""></MapImg>
        </>
    );
}
export default Landing;

const LandingWshowContainer = styled.div`
    width: 100%;
    object-fit: cover;

    height: 820px;
    flex-shrink: 0;
    background: #0c142d;
    @media screen and (max-width: ${PAD_MAX_WIDTH}px) {
        height: 600px;
    }
`;
const LandingContainer = styled.div`
    object-fit: cover;

    width: 100%;
    display: flex;
    justify-content: center;
    @media screen and (max-width: ${PAD_MAX_WIDTH}px) {
        height: 600px;
    }
    margin: 0 auto;
    flex-shrink: 0;
`;

const LandingWshowImg = styled.img`
    width: ${PAD_MAX_WIDTH}px;
    height: 820px;
    object-fit: cover;
    flex-shrink: 0;
`;

const FireImg = styled.img`
    position: absolute;
    width: 100%;
    height: 600px;
    object-fit: cover;
    opacity: 0.5;
    background: #000;
    z-index: 1002;
    @media screen and (max-width: ${PAD_MAX_WIDTH}px) {
        width: 100%;
        left: 50%;
        transform: translate(-50%, 0);
    }
`;

const LandingFireBox = styled.div`
    margin-top: 80px;
    width: 100%;
    height: 600px;
    @media screen and (max-width: ${PAD_MAX_WIDTH}px) {
        height: 600px;
    }
`;
const TextContainer = styled.div`
    z-index: 1005;
    position: relative;
    padding-top: 60px;
    width: 100%;
    margin: 0 auto;
    h1 {
        text-align: center;
        font-family: 'NanumSquareNeo';
        font-size: 36px;
        font-weight: 900;
        line-height: 48px; /* 133.333% */
    }
    h2 {
        font-family: 'NanumSquareNeo';
        font-size: 24px;
        font-weight: 800;
        line-height: 32px; /* 133.333% */
    }
    h3 {
        font-size: 24px;

        font-weight: 600;
        line-height: 32px; /* 133.333% */
    }
    p {
        margin-top: 8px;
        font-size: 18px;
        font-weight: 600;
        line-height: 26px; /* 144.444% */
        letter-spacing: -0.45px;
    }
`;
const TextBox = styled.div`
    width: 400px;
    margin: 0 auto;
    @media screen and (max-width: ${PAD_MAX_WIDTH}px) {
        width: 360px;
        h1 {
            font-size: 28px;
        }
        h2 {
            font-size: 24px;
        }
        h3 {
            font-size: 20px;
        }
    }
`;

const TimeBox = styled(FlexBox)`
    justify-content: space-between;
    margin-top: 40px;
    margin-bottom: 20px;

    h3 {
        text-align: right;
    }
`;

const Line = styled.div`
    width: 400px;
    height: 1px;
    flex-shrink: 0;
    background: #d9d9d9;
    @media screen and (max-width: ${PAD_MAX_WIDTH}px) {
        width: 360px;
    }
`;

const InfoText = styled.div`
    margin-top: 76px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.35px;
`;

const MapImg = styled.img`
    margin-top: 80px;
    width: 100%;
    height: 800px;
    object-fit: cover;
    overflow: hidden;

    @media screen and (max-width: ${PAD_MAX_WIDTH}px) {
        height: 300px;
        object-fit: cover;
        object-position: center;
    }

    @media screen and (min-width: ${MOBILE_MAX_WIDTH}px) and (max-width: ${PAD_MAX_WIDTH}px) {
        height: 500px;
    }
`;

const MobileNavigate = styled.div`
    position: fixed;
    background-color: #fff;

    width: 100%;
    height: 100px;
    bottom: 0;
    z-index: 2000;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    p {
        color: var(--Font-02_black, #111);
        font-size: 14px;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
        letter-spacing: -0.35px;
    }
`;

const MobileBtn = styled.button`
    margin-top: 4px;
    width: 100%;
    height: 48px;
    flex-shrink: 0;
    font-size: 20px;
    background-color: rgba(0, 0, 0, 0.1); // 배경을 반투명 검은색으로 설정
    color: white;
    color: #fff;

    border-radius: 4px;
    background: #07b0c7;
    font-family: NanumSquareNeo;
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: 24px; /* 150% */
    letter-spacing: -0.4px;
`;
const MobileLandingWshowImg = styled.img`
    object-fit: cover;
    width: 100%;
    height: auto;

    @media screen and (min-width: ${MOBILE_MAX_WIDTH}px) {
        object-fit: contain;
    }
`;
