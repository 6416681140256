import styled from 'styled-components';
import { FlexBox } from '../../styles/GlobalStyle';
import facebook from 'assets/layout/header/facebook.svg';
import instagram from 'assets/layout/header/instagram.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import hamburgerImg from '../../assets/landing/hamburger.svg';
import HamburgerPopup from './HamburgerPopup';
import { PAD_MAX_WIDTH } from 'const/overview';
function Header() {
    const [activeHeader, setActiveHeader] = useState('');
    const [hamburger, setHamburger] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const handleHeader = (e: any) => {
        if (e.target.id === 'event') alert('추후 추가될 서비스입니다.');
        else {
            setActiveHeader(e.target.id);
            navigate(`/${e.target.id}`);
        }
    };
    const handleHamburger = () => {
        setHamburger(true);
    };
    return (
        <>
            {hamburger && <HamburgerPopup setHamburger={setHamburger} />}
            <BackgroundBox pathname={location.pathname}>
                <HeaderContainer>
                    <LogoBox onClick={handleHeader}>
                        목포해상<p>W</p>쇼
                    </LogoBox>
                    <H2Box>
                        <HeaderBox
                            activeHeader={activeHeader}
                            id="event"
                            onClick={handleHeader}
                        >
                            이벤트
                        </HeaderBox>
                        <HeaderBox
                            activeHeader={activeHeader}
                            id="notice"
                            onClick={handleHeader}
                        >
                            공지사항
                        </HeaderBox>
                        <HeaderBox
                            activeHeader={activeHeader}
                            id="inquiry"
                            onClick={handleHeader}
                        >
                            문의사항
                        </HeaderBox>
                    </H2Box>
                    <SnsContainer>
                        <Img
                            src={facebook}
                            alt=""
                            onClick={() =>
                                window.open(
                                    'https://www.facebook.com/wshowmokpo',
                                    '_blank',
                                )
                            }
                        />
                        <Img
                            src={instagram}
                            alt=""
                            onClick={() =>
                                window.open(
                                    'https://www.instagram.com/mokpowshow/',
                                    '_blank',
                                )
                            }
                        />
                        <Mimg
                            src={hamburgerImg}
                            alt=""
                            onClick={handleHamburger}
                        />
                    </SnsContainer>
                </HeaderContainer>
            </BackgroundBox>
        </>
    );
}

export default Header;

const BackgroundBox = styled.div<{ pathname: string }>`
    background-color: ${props => (props.pathname !== '/' ? '#111' : '#0C142D')};
    width: 100%;
`;
const HeaderContainer = styled.nav`
    width: 100%;

    display: flex;
    height: 80px;

    margin: 0 auto;
    align-items: center;
    padding: 22px;
`;

const HeaderBox = styled.div<{ activeHeader: string; id: string }>`
    color: #fff;
    white-space: nowrap;
    /* PC/Title/KR/T5_KR_Sb */
    font-family: Pretendard;
    font-size: 18px;
    font-style: normal;
    font-weight: ${props => (props.activeHeader === props.id ? 600 : 400)};
    line-height: 26px; /* 144.444% */
    letter-spacing: -0.45px;
`;

const LogoBox = styled(FlexBox)`
    color: #fff;
    font-family: 'NanumSquareNeo';
    white-space: nowrap;
    font-size: 28px;
    font-style: normal;
    font-weight: 900;
    line-height: 36px; /* 128.571% */
    cursor: pointer;

    @media screen and (max-width: ${PAD_MAX_WIDTH}px) {
        font-size: 20px;
    }

    p {
        color: #07b0c7;
        white-space: nowrap;
    }
`;

const H2Box = styled(FlexBox)`
    margin-left: 80px;
    display: inline-flex;
    align-items: center;
    gap: 64px;
    cursor: pointer;
    :hover {
        color: #07b0c7;
    }
    @media screen and (max-width: ${PAD_MAX_WIDTH}px) {
        display: none;
    }
`;

const SnsContainer = styled(FlexBox)`
    margin-left: auto;
    :nth-child(1) {
        margin-right: 20px;
    }
    @media screen and (max-width: ${PAD_MAX_WIDTH}px) {
        :nth-child(1) {
            margin-right: 12px;
        }
    }
`;
const Img = styled.img`
    cursor: pointer;
    @media screen and (max-width: ${PAD_MAX_WIDTH}px) {
        margin-right: 12px;
        width: 28px;
    }
`;

const Mimg = styled.img`
    @media screen and (max-width: ${PAD_MAX_WIDTH}px) {
        display: none;
    }
`;
